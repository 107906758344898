import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs/Rx';
import { environment  } from '../../environments/environment'

export class Subscribable<T> {

  private valueSource: Subject<T> = new ReplaySubject<T>(1);
  public value: Observable<T>;
  private _value: T;

  constructor() {
      this.value = this.valueSource.asObservable();
  }

  public set(val: T) {
      this.valueSource.next(val);
      this._value = val;
  }

  public get(): T {
      return this._value;
  }

  public send(data) {
      this.valueSource.next(data);
  }

  public Create(data) {

  }

}

export class MessageData {
  event: string
  value: Object
}

@Injectable()
export class HelpdeskCaseWsService {
   // public variable
   public isNodeConnected = false; // use for all
   private subject: Subscribable<MessageData>;
   private websocket: any;
   private msgTemp: any;
   private msgOn;
   constructor() {
    this.connect();
  }

  public connect(): Subscribable<MessageData> {
    if (!this.isNodeConnected) {
        this.subject = this.create(environment.environment.API_WS);
        this.subject.value.subscribe((msg) => {
          this.msgTemp = msg;
          // this.msgOn = JSON.parse(this.msgTemp);
          // if ( this.msgOn['event'] === 'case-connet') {
          //   console.log('this.connect()');
          //   this.connect()
          //   localStorage.setItem('wsStatus', 'connect');
          // } else {
          //   setTimeout(function() { this.connect() }, 10000);
          // }
        })
    }
    return this.subject;
  }

  private create(url): Subscribable<MessageData> {
    this.websocket = new WebSocket(url);
    const subject = new Subscribable<MessageData>();
    this.websocket.onopen = (msg) => {
        // console.log('onopen: ', msg)
        this.isNodeConnected = true;
          // for init data
        this.send({
          action:  'connect',
        });
        // console.log('Successfully connected: ' + url, this.websocket);
    }

    this.websocket.onmessage = (msg) => {
        // console.log('onmessage')
        // console.log(msg.data);
      subject.set(msg.data)
        // console.log(msg);
        this.msgOn = JSON.parse(msg.data);
        // console.log('onmessage : ', this.msgOn['event']);
         if ( this.msgOn['event'] === 'case-connet') {
          // console.log('this.connect()');
          localStorage.setItem('wsStatus', 'connect');
          this.connect()
        }

    }

    this.websocket.onclose = (msg) => {
          // console.log('onclose')
          this.isNodeConnected = false;
          subject.send({err: 'socket close'})
          this.connect();
    }
    // this.connect();
    return subject
  }
  public getSubject() { return this.subject.value; }

  public send(data) {
    // console.log('isNodeConnected', this.isNodeConnected);
    if (!this.isNodeConnected) {
        this.connect();
    }

    // console.log('this.websocket.readyState', this.websocket.readyState);
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.send(JSON.stringify(data));
    }

  }
}
