import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment'

@Injectable()
export class HelpdeskAgentService {
  public prefixname_type = [{
    type: 'mr',
    title: 'นาย'
  } , {
    type: 'miss',
    title: 'นาง'
  } , {
    type: 'missis',
    title: 'นางสาว'
  }]
  public group_type = [{
    id: 1,
    title: 'admin'
  } , {
    id: 2,
    title: 'helpdask'
  } , {
    id: 3,
    title: 'supervisor'
  }]
  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute) { }

  getAgent(token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/agent', {headers});
  }

  getAgentById(token, id) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/agent/' + id, {headers});
  }

  patchAgentById(token, data, id) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.patch( environment.environment.API_BLACKEND + '/api/agent/' + id, data, {headers});
  }

  postAgentById(token, data) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.post( environment.environment.API_BLACKEND + '/api/agent', data, {headers});
  }

  deleteAgentById(token, id) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.delete( environment.environment.API_BLACKEND + '/api/agent/' + id, {headers});
  }

  getGroupAgent(token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/group', {headers});
  }
}
