import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment'
import { Body } from '@angular/http/src/body';

@Injectable()
export class HelpdeskCaseService {
  public URL_API_BLACKEND = environment.environment.API_BLACKEND;
  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute) { }

  getCase(token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/case', {headers});
  }

  getCaseById(caseId, token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/case/' + caseId, {headers});
  }

  getCaseClose(query, token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get( environment.environment.API_BLACKEND + '/api/caseclose?start=' + query.start + '&limit=' + query.limit +
    '&search_detail=' + query.search_detail + '&search_contact=' + query.search_contact + '&search_channel=' + query.search_channel +
    '&date_start=' + query.search_date_start + '&date_end=' + query.search_date_end + '&action=' + query.action, {headers});
  }

  getCaseReceived(query, token) {
    const headers = new HttpHeaders({'Authorization': token});
    console.log(query.start, query.limit);
    return this.http.get( environment.environment.API_BLACKEND + '/api/casereceived?start=' + query.start + '&limit=' + query.limit +
          '&search_detail=' + query.search_detail + '&search_contact=' + query.search_contact + '&search_channel=' + query.search_channel +
          '&date_start=' + query.search_date_start + '&date_end=' + query.search_date_end + '&action=' + query.action +
          '&role=' + query.role, {headers});
  }

  getCaseWaiting(query, token) {
    const headers = new HttpHeaders({'Authorization': token});
    console.log(query.start, query.limit);
    return this.http.get( environment.environment.API_BLACKEND + '/api/casewaiting?start=' + query.start + '&limit=' + query.limit +
          '&search_detail=' + query.search_detail + '&search_contact=' + query.search_contact + '&search_channel=' + query.search_channel +
          '&date_start=' + query.search_date_start + '&date_end=' + query.search_date_end + '&action=' + query.action +
          '&role=' + query.role, {headers});
  }

  postCase(token, data) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.post( environment.environment.API_BLACKEND + '/api/case', data, {headers});
  }

  deleteCaseById(token, caseId) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.delete( environment.environment.API_BLACKEND + '/api/case/' + caseId, {headers});
  }

  testUploadfile(token, fileFormdata) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.post(environment.environment.API_BLACKEND + '/api/case/', fileFormdata , {headers});
  }

  updateCase(token, data, id) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.patch(environment.environment.API_BLACKEND + '/api/case/' + id, data , {headers});
    // const req = new HttpRequest<FormData>('PATCH', environment.API_BLACKEND + '/api/case/' + id, fileFormdata,
    // {headers, reportProgress: true})
    // return req;
  }

  sendCoorMsg(token, data, id) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.post(environment.environment.API_BLACKEND + '/api/coordinator/msg/' + id, data , {headers});
  }

  notificationsCase(token) {
    const headers = new HttpHeaders({'Authorization': token});
    return this.http.get(environment.environment.API_BLACKEND + '/api/notifications', {headers});
  }


}
